.projects {
  background: linear-gradient(
    to right,
    $color-gradient-start,
    $color-gradient-end
  );
  width: 100%;

  &__title-row {
    width: 100%;
    background-color: $color-dark-1;
    text-align: center;
    padding-bottom: 2rem;
  }

  &__project-list {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    grid-auto-rows: min-content;
    justify-content: center;
    justify-items: center;
    grid-gap: 5rem;
    margin: 10rem auto;

    @include respond(tab-land) {
      grid-template-columns: 1fr;
    }
  }
}
