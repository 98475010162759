$color-gradient-start: #17d396;
$color-gradient-end: #07f7f7;

$color-black: #000;
$color-white: #fff;

$color-primary: #00d7bb;
$color-dark-1: #22252c;
$color-dark-2: #313741;

$color-contact-form: #ccc;
$color-resume-btn-shadow: #16d59cb3;

$navbar-height: 8rem;
