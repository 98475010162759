@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-weight: 400;
  line-height: 1.6;
  color: $color-white;
  font-family: 'Open sans', sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.heading-1 {
  font-size: 12rem;
  font-weight: 500;
  line-height: 1;
}

.heading-2 {
  font-size: 5rem;
  font-weight: 800;

  span {
    font-weight: 300;
  }

  &--section-title {
    background: linear-gradient(
      to right,
      $color-gradient-start,
      $color-gradient-end
    );
    color: transparent;
    background-clip: text;
    text-transform: uppercase;
  }
}

.heading-3 {
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
}

.heading-4 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-black;
}

.paragraph {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.7;

  &--primary {
    color: $color-primary;
  }

  &--project-text {
    color: $color-primary;
    font-weight: 600;
  }
}

.list {
  list-style: none;

  &__item {
    font-size: 1.7rem;
    margin-bottom: 1.6rem;
    font-weight: 400;

    span {
      color: $color-primary;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
