.close-btn {
  color: $color-white;
  background-color: $color-primary;
  border: none;
  border-radius: 50%;
  font-size: 1.6rem;
  font-weight: 700;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;

  &--nav-modal {
    position: absolute;
    right: 6rem;
    top: 3rem;
    width: 4rem;
    height: 4rem;
    font-size: 2.5rem;
  }
}
